<div *ngIf="!showRegisterMessage" [style.margin]="getMarginOuter()">
    <!-- <h2 class="vc_custom_heading" style="font-size: 28px;line-height: 1.4;text-align: left;font-family:Source Sans Pro;font-weight:400;font-style:normal;">Jetzt registrieren!</h2> -->
    <!-- <h3 class="vc_custom_heading" style=" font-size: 23px;line-height: 28px;text-align: left;font-family:Source Sans Pro;font-weight:400;font-style:normal;">Deine Vorteile auf einen Blick:</h3> -->
    <div class="wpb_wrapper">
        <div class="wpb_text_column wpb_content_element  vc_custom_1485638236800">
            <div class="wpb_wrapper">
                <h3>{{'REGISTER.EUBERSICHTSTEXT1' | translate}} {{'REGISTER.EUBERSICHTSTEXT2' | translate}}
                </h3>
                <br><div *ngIf="kreditkarte"> {{'REGISTER.EUBERSICHTSTEXT3' | translate}}
                {{'REGISTER.EUBERSICHTSTEXT4' | translate}}<br></div><br>{{'REGISTER.EUBERSICHTSTEXT5' | translate}}
                <br><br>
            </div>
        </div>
    </div>
    <div id="formTabs" class="inner-wrap formbackground">
        <div class="main">
    
            <!-- sprachauswahl -->
            <app-sprachauswahl (onReloadData)="this.reloadData()" ></app-sprachauswahl>

            
            <div class="wpb_text_column wpb_content_element ">
                <div class="wpb_wrapper">
                    <h3>{{'REGISTER.NEU_REGISTRIEREN' | translate}}</h3>
                    <div class="divider-wrap" data-alignment="default">
                        <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
                    </div>
                    <p></p>
                </div>
            </div>

            <div id="form-container">
                <!-- Hauptkontakt -->
                <form id="hauptkontakt">
                    <!-- Hauptkontakt -->
                    <dx-form #formKontakt [(formData)]="daten" validationGroup="mitgliedKontaktValidator" [colCount]="3" [labelLocation]="'top'" [showValidationSummary]="true" (onFieldDataChanged)="onKontaktDataChanged($event)">
                        <!-- Registrierung als -->
                        <!-- <dxi-item editorType="dxSelectBox" [dataField]="'registrierungals'" valueExpr="value" displayExpr="text" [editorOptions]="registerAlsSelectOptions" style="word-wrap: break-word; white-space: normal; ">
                            <dxo-label text="{{'REGISTER.REGISTRIERUNG_ALS' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'MITGLIEDWERDEN.REGISTRIERUNGSTYPAUSWAEHLEN' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item [visible]="this.registrierungals === 1" itemType="empty"></dxi-item>
                        <dxi-item [visible]="this.registrierungals === 1" itemType="empty"></dxi-item> -->

        
                        <!-- Titel -->
                        <!-- <dxi-item [dataField]="'titel'" editorType="dxTextBox" >
                            <dxo-label text="{{'REGISTER.TITEL' | translate}}"></dxo-label>
                            <dxi-validation-rule type="stringLength" [max]="20" message="{{'REGISTER.TITEL_MAX_20' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item itemType="empty"></dxi-item> -->
                        <!-- Vorname -->
                        <dxi-item [dataField]="'vorname'" type="required" [editorOptions]="vornameOptions">
                            <dxo-label text="{{'REGISTER.VORNAME' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.VORNAME_EINGEBEN' | translate}}"></dxi-validation-rule>
        
                            <dxi-validation-rule type="pattern" [pattern]="namePattern" message="{{'REGISTER.KEINE_ZAHLEN_VORNAMEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.VORNAME_MAXIMAL_50' | translate}}"></dxi-validation-rule>
                          </dxi-item>
                        <!-- Nachname -->
                        <dxi-item [dataField]="'nachname'" type="required" [editorOptions]="nachnameOptions">
                            <dxo-label text="{{'REGISTER.NACHNAME' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.NACHNAME_EINGEBEN' | translate}}"></dxi-validation-rule>
      
                            <dxi-validation-rule type="pattern" [pattern]="namePattern" message="{{'REGISTER.KEINE_ZAHLEN_NACHNAMEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.NACHNAME_MAXIMAL_50' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Anrede -->
                        <dxi-item [dataField]="'anrede'" type="required" editorType="dxSelectBox" valueExpr="value" displayExpr="text" [editorOptions]="anredeSelectOptions">
                            <dxo-label text="{{'REGISTER.ANREDE' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.ANREDE_AUSWAEHLEN' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Nationalität -->
                        <dxi-item [dataField]="'nationalitaet'" type="required" editorType="dxSelectBox" valueExpr="id" displayExpr="name" [editorOptions]="landSelectOptions">
                            <dxo-label text="{{'REGISTER.NATIONALITAET' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'PROFIL.NATIONALITAETAUSWAEHLEN' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Verheirateter Name -->
                        <!-- <dxi-item *ngIf="this.registrierungals === 1" [dataField]="'verheirateterName'">
                            <dxo-label text="{{'REGISTER.VERHEIRATETER_NAME' | translate}}"></dxo-label>
                            <dxi-validation-rule type="pattern" [pattern]="namePattern" message="{{'REGISTER.ZAHLEN_VERHEIRATETEN_NAME' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.VERHEIRATETER_NAME_MAX_50' | translate}}"></dxi-validation-rule>
                        </dxi-item> -->
                        <!-- Geburtsort -->
                        <dxi-item *ngIf="this.daten.nationalitaet===gdService.it_id" [dataField]="'gebort'" type="required" editorType="dxSelectBox" valueExpr="name" displayExpr="name" [editorOptions]="geburtsortSelectOptions">
                            <dxo-label text="{{'REGISTER.GEBURTSORT_ITALIEN_NUR_STAAT' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.GEBURTSORT_EINGEBEN' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Geburtsort Ausland -->
                        <dxi-item *ngIf="this.daten.nationalitaet!==gdService.it_id" [dataField]="'gebort'">
                            <dxo-label text="{{'REGISTER.GEBURTSORT' | translate}}"></dxo-label>
                            <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.GEBURTSORT_MAX_50' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Geburtsdatum -->
                        <dxi-item  [dataField]="'gebdatum'" editorType="dxDateBox" [editorOptions]="geburtsdatumOptions" autocomplete="false" >
                            <dxo-label text="{{'REGISTER.GEBURTSDATUM' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.GEBURTSDATUM_EINGEBEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="custom" [validationCallback]="validateGebdatum" message="{{'REGISTER.VOLLJAEHRIG_ODER_ERZIEHUNGSBERECHTIGTEN' | translate}}"></dxi-validation-rule>
                        </dxi-item>

                        <!-- Steuernummer -->
                        <dxi-item [visible]="daten.nationalitaet===gdService.it_id" [dataField]="'steuernummer'" editorType="dxTextBox" [editorOptions]="steuernummerOptions">
                            <dxo-label text="{{'REGISTER.STEUERNUMMER' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.STEUERNUMMER_EINGEBEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [min]="16" message="{{'REGISTER.STEUERNUMMER_MIN_16' | translate}}"></dxi-validation-rule>
                            <!-- <dxi-validation-rule type="custom" [(validationCallback)]="validateSteuernummer" [reevaluate]="true" [message]="daten.steuerfehlerTxt"></dxi-validation-rule> -->

                        </dxi-item>
                        <dxi-item [visible]="daten.nationalitaet===gdService.it_id" itemType="empty"></dxi-item>
                        <dxi-item [visible]="daten.nationalitaet===gdService.it_id" itemType="empty"></dxi-item>
                        <!-- <dxi-item itemType="group" [colCount]="3"> -->
                            <!-- <dxi-item itemType="group" [colCount]="1"> -->
                        <!-- <dxi-item itemType="group" caption="Ich habe eine Mitglieds- bzw. Kontaktkarte" >
                        </dxi-item> -->
                        <dxi-item [visible]="kontakte && mitgliedsnummer" itemType="group" cssClass="nummer" >
                            <dxi-item itemType="simple" editorType="dxCheckBox" cssClass="nummer-ja" 
                            [editorOptions]="hatNummerOptions">
                            </dxi-item>
                        </dxi-item>
                        <dxi-item  [visible]="kontakte && mitgliedsnummer" itemType="group" horizontalAlignment="left" >
                            <dxi-item  itemType="button" horizontalAlignment="left" [buttonOptions]="this.buttonInfoKarte" [colSpan]="2"></dxi-item>
                        </dxi-item>
                        <dxi-item  [visible]="kontakte && mitgliedsnummer" itemType="empty" >
                        </dxi-item>
                        <!-- <dxi-item  itemType="empty" >
                        </dxi-item> -->
                        <dxi-item [visible]="this.hatNummer !== null && !this.hatNummer" [colSpan]="3" [colCount]="3" itemType="group">
                        
                            <dxi-item itemType="group" caption="{{'REGISTER.DEIN_WOHNSITZ' | translate}}" [colSpan]="3"></dxi-item>
    
                            <!-- <dxi-item *ngIf="kontaktauswahlListStore && this.registrierungals === 2" [colSpan]="2"  itemType="simple" editorType="dxRadioGroup"
                            [editorOptions]="{ dataSource: kontaktauswahlListStore, layout:'horizontal', valueExpr: 'value', displayExpr: 'text' }"> -->
                                    <!-- Land -->
                            <dxi-item [dataField]="'land_id'" type="required" editorType="dxSelectBox" valueExpr="id" displayExpr="name" [editorOptions]="land2SelectOptions">
                                <dxo-label text="{{'REGISTER.LAND' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.LAND_WAEHLEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Provinz -->
                            <dxi-item *ngIf="this.daten.land_id===gdService.it_id" [dataField]="'provinz'" editorType="dxSelectBox" [editorOptions]="provinzSelectOptions">
                                <dxo-label text="{{'REGISTER.PROVINZ' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.PROVINZ_WAEHLEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Ort Bozen -->
                            <dxi-item #ort *ngIf="this.daten.provinz==='Bozen'" [dataField]="'ort'" editorType="dxSelectBox" valueExpr="name" displayExpr="name" type="required" [editorOptions]="ortSelectOptions">
                                <dxo-label text="{{'REGISTER.ORT' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ORT_EINGEBEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Ort Rest -->
                            <dxi-item *ngIf="this.daten.provinz!=='Bozen'" [dataField]="'ort'" type="required">
                                <dxo-label text="{{'REGISTER.ORT' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ORT_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.ORT_MAX_50' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- PLZ -->
                            <dxi-item [dataField]="'plz'" type="required" [disabled]="this.daten.provinz ==='Bozen'">
                                <dxo-label text="{{'REGISTER.POSTLEITZAHL' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.POSTLEITZAHL_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="10" [reevaluate]="true" message="{{'REGISTER.POSTLEITZAHL_MAX_10' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                        <!-- </dxi-item> -->
                        <!-- <dxi-item itemType="group" [colCount]="1"> -->
                            <!-- Straße -->
                            <dxi-item [dataField]="'strasse'" type="required">
                                <dxo-label text="{{'REGISTER.STRASSE' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.STRASSE_WEILER' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="150" message="{{'REGISTER.STRASSE_MAX_150' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Hausnummer -->
                            <dxi-item [dataField]="'hausnr'" type="required">
                                <dxo-label text="{{'REGISTER.HAUSNUMMER' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.HAUSNUMMER_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="10" [reevaluate]="true" message="{{'REGISTER.HAUSNUMMER_MAX_10' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Weiler -->
                            <dxi-item [dataField]="'weiler'" type="required">
                                <dxo-label text="{{'REGISTER.WEILER' | translate}}"></dxo-label>
                                <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.WEILER_MAX_50' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                        </dxi-item>
                            <!-- </dxi-item> -->
                    <!-- Mitgliedsnummer -->
                    <dxi-item [visible]="this.hatNummer !== null && this.hatNummer" [colSpan]="3" [colCount]="3" itemType="group">
                        <dxi-item *ngIf="!this.kontaktnummer" itemType="group" caption="{{'REGISTER.DEINEMITGLIEDSNUMMER' | translate}}" [colSpan]="3"></dxi-item>
                        <dxi-item *ngIf="this.kontaktnummer" itemType="group" caption="{{'REGISTER.DEINEKONTAKTNUMMER' | translate}}" [colSpan]="3"></dxi-item>
                        <dxi-item *ngIf="mgdnummerEingabe(enuEingabeMitgliedsnummer.MitSektion) && !this.kontaktnummer" [dataField]="'mitgliedsnr'" type="required" editorType="dxTextBox" [editorOptions]="mitgliedsnummerOptions">
                            <dxo-label text="{{'REGISTER.MITGLIEDSNUMMERFORMAT' | translate}}" ></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.MITGLIEDSNUMMEREINGEBEN1' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="pattern" [pattern]="mitgliedsnummerPattern" message="{{'REGISTER.MITGLIEDSNUMMERMUSSFORMAT' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item *ngIf="mgdnummerEingabe(enuEingabeMitgliedsnummer.OhneSektion) && !this.kontaktnummer" [dataField]="'mitgliedsnr'" type="required" editorType="dxTextBox" [editorOptions]="mitgliedsnummerOptions2">
                            <dxo-label text="{{'REGISTER.MITGLIEDSNUMMERFORMAT2' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.MITGLIEDSNUMMEREINGEBEN2' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [max]="5" message="Die Mitgliedsnummer ist max. 5 Zahlen lang"></dxi-validation-rule>
                            <!-- <dxi-validation-rule type="pattern" [pattern]="mitgliedsnummerPattern" message="Die Mitgliedsnummer muß das Format NNNNN haben"></dxi-validation-rule> -->
                        </dxi-item>
                        <!-- Getrennte Ausgabe weil das *ngIf vom dxo-label nicht immer auf Änderungen reagiert hat-->
                        <dxi-item *ngIf="this.kontaktnummer && mgdnummerEingabe(enuEingabeMitgliedsnummer.MitSektion)" [dataField]="'nummer'" type="required" editorType="dxTextBox"   [editorOptions]="kartennummerOptions">
                            <dxo-label text="{{'REGISTER.KONTAKTNUMMERFORMAT' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.MITGLIEDSNUMMEREINGEBEN2' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item *ngIf="this.kontaktnummer && mgdnummerEingabe(enuEingabeMitgliedsnummer.OhneSektion)" [dataField]="'nummer'" type="required" editorType="dxTextBox"   [editorOptions]="kartennummerOptions">
                            <dxo-label text="{{'REGISTER.KONTAKTNUMMERFORMAT2' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.MITGLIEDSNUMMEREINGEBEN2' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item itemType="group" horizontalAlignment="left"  [colSpan]="2">
                            <dxi-item *ngIf="gdService.istKunde(enuKunde.AVS)" itemType="button" horizontalAlignment="left" [buttonOptions]="this.buttonInfoMitgliedsnummer" [colSpan]="2"></dxi-item>
                            <dxi-item *ngIf="gdService.istKunde(enuKunde.AVS) && this.kontaktnummer" itemType="button" horizontalAlignment="left" [buttonOptions]="this.buttonInfoKontaktnummer" [colSpan]="2"></dxi-item>
                            <dxi-item *ngIf="!gdService.istKunde(enuKunde.AVS)" itemType="empty" [colSpan]="2"></dxi-item>
                        </dxi-item>

                        <!-- prüfen Button -->
                        <dxi-item itemType="simple" [colSpan]="3">
                            <div *dxTemplate style="text-align: left;">
                                <dx-button text="{{'REGISTER.PRUEFEN' | translate}}" (onClick)="onClickPruefen($event)" [template]="'buttonTemplate'" validationGroup="mitgliedKontaktValidator">
                                    <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                        <app-button [buttonData]="buttonData"></app-button>
                                    </div>
                                </dx-button>
                            </div>
                         </dxi-item>
                        <dxi-item *ngIf="this.istGeprueftesMitglied" itemType="simple" [colSpan]="2" editorType="dxCheckBox" [editorOptions]="{readOnly: true, text: this.mitgliedsPruefungTxt ,value: this.istGeprueftesMitglied, isValid: this.istGeprueftesMitglied}">
                        </dxi-item>
                    </dxi-item>

                        <!-- </dxi-item> -->
                        <!-- <dxi-item [visible]="this.registrierungals === 2" itemType="empty"></dxi-item>
                        <dxi-item [visible]="this.registrierungals === 2" itemType="empty"></dxi-item> -->
                        <!-- <dxi-item [visible]=" this.daten.land_id!==gdService.it_id && this.hatkeineNummer" itemType="empty"></dxi-item> -->
                        <dxi-item  *ngIf='!this.hatNummer ||  istGeprueftesMitglied' itemType="group" caption="{{'REGISTER.DEINE_KONTAKTE' | translate}}" [colSpan]="3"></dxi-item>

                        <!-- Email -->
                        <!-- <dxi-item  [dataField]="'email'" *ngIf='!this.hatNummer ||  istGeprueftesMitglied' [disabled]='true' type="required">
                            <dxo-label text="{{'REGISTER.E-MAIL' | translate}}"></dxo-label>
                        </dxi-item> -->
                        <dxi-item  [dataField]="'email'" *ngIf='!this.hatNummer ||  istGeprueftesMitglied' [disabled]='false' type="required">
                            <dxo-label text="{{'REGISTER.E-MAIL' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.E-MAIL_EINGEBEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="email" message="{{'REGISTER.E-MAIL_UNGUELTIG' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [max]="150" message="{{'REGISTER.E-MAIL_MAX_150' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Telefonnummer -->
                        <dxi-item  [dataField]="'telefon'" *ngIf='!this.hatNummer ||  istGeprueftesMitglied'  editorType="dxTextBox">
                            <dxo-label text="{{'REGISTER.TELEFONNUMMER' | translate}} "></dxo-label>
                            <dxi-validation-rule type="stringLength" [max]="20" message="{{'REGISTER.TELEFONNUMMER_MAX_20' | translate}}"></dxi-validation-rule>
                            <!-- <dxi-validation-rule type="required" message="Telefonnummer eingeben"></dxi-validation-rule> -->
                            <!-- <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Ungültige Nummer"> </dxi-validation-rule> -->
                        </dxi-item>
                        <dxi-item  itemType="empty"></dxi-item>
                            <!-- prüfen Button -->

                            <!-- <dxi-item itemType="simple" [colSpan]="3">
                                <div *dxTemplate style="text-align: left;">
                                    <dx-button text="prüfen" (onClick)="onClickPruefen($event)" [template]="'buttonTemplate'" validationGroup="mitgliedKontaktValidator">
                                        <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                            <div class="nectar-button large regular accent-color regular-button has-icon"  data-hover-text-color-override="#fff" style="background-color: rgb(122, 181, 29) !important;visibility: visible;margin-bottom:0px;display:block;"><i class="icon-button-arrow"></i><span>{{buttonData.text}}</span></div>
                                        </div>
                                    </dx-button>
                                </div>
                             </dxi-item> -->
                    </dx-form>
                </form>
                <!-- Erziehungsberechtigte von Server abgerufen -->
                <!-- <form id="erzberechtigteserver" style="padding-top:20px;">
                    <dx-form #formKontaktErzServer [(formData)]="daten" validationGroup="erzberechtServerValidator" [visible]="hatErzberechtigtenServer" [colCount]="3" [labelLocation]="'top'" [showValidationSummary]="true">
                        <dxi-item itemType="group" [colCount]="3" [colSpan]="3">
                            <dxi-item itemType="group" caption="Erziehungsberechtigte" [colSpan]="2"></dxi-item>
                            <dxi-item itemType="button" horizontalAlignment="right" cssClass="remove-erzber-button" [buttonOptions]="removErzButtonOptions"></dxi-item>
                        </dxi-item>
                    </dx-form>
                </form> -->
                <!-- Erziehungsberechtigter Neu Eingabe-->
                <form id="erzberechtigter" style="padding-top:20px;" >
                    <!-- Erziehungsberechtigter -->
                    <dx-form *ngIf="hatErzberechtigten" #formKontaktErz [(formData)]="daten" validationGroup="erzberechtKontaktValidator" [colCount]="3" [labelLocation]="'top'" [showValidationSummary]="true" (onFieldDataChanged)="onErzberechtigterDataChanged($event)">
                        <dxi-item itemType="group" [colCount]="3" [colSpan]="3">
                            <dxi-item itemType="group" [caption]="bezeichnungErZ" [colSpan]="2"></dxi-item>
                            <dxi-item itemType="button" horizontalAlignment="right" cssClass="remove-erzber-button" [buttonOptions]="removErzButtonOptions"></dxi-item>
                            <!-- Registrierung als Erz-->
                            <!-- <dxi-item type="required" [dataField]="'registrierungalsErz'" editorType="dxSelectBox" valueExpr="value" displayExpr="text" [editorOptions]="registerAlsErzSelectOptions">
                                <dxo-label [text]="bezeichnungErZ+' ' + this.translate.instant('MITGLIEDWERDEN.IST')"></dxo-label>
                                <dxi-validation-rule type="required" message=message="{{'REGISTER.ERZTYPAUSWAEHLEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <dxi-item [visible]="this.registrierungalsErz === 1" itemType="empty"></dxi-item>
                            <dxi-item [visible]="this.registrierungalsErz === 1" itemType="empty"></dxi-item>     -->
       
                            <!-- Titel Erz-->
                            <!-- <dxi-item [dataField]="'erzberechtigte[0].titel'">
                                <dxo-label text="{{'REGISTER.TITEL' | translate}}"></dxo-label>
                                <dxi-validation-rule type="stringLength" [max]="20" message="{{'REGISTER.TITEL_MAX_20' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <dxi-item itemType="empty"></dxi-item> -->
                            <!-- Vorname Erz-->
                            <dxi-item [dataField]="'erzberechtigte[0].vorname'" type="required" [editorOptions]="vornameErzOptions">
                                <dxo-label text="{{'REGISTER.VORNAME' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZIEHUNGSBERECHTIGTER_VORNAME_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="pattern" [pattern]="namePattern" message="{{'REGISTER.ERZIEHUNGSBERECHTIGTER_ZAHL_VORNAMEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.VORNAME_MAX_50' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Nachname Erz -->
                            <dxi-item [dataField]="'erzberechtigte[0].nachname'" type="required" [editorOptions]="nachnameErzOptions">
                                <dxo-label text="{{'REGISTER.NACHNAME' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZIEHUNGSBERECHTIGTER_NACHNAME_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="pattern" [pattern]="namePattern" message="{{'REGISTER.ERZIEHUNGSBERECHTIGTER_ZAHLEN_NACHNAMEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.NACHNAME_MAX_50' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Anrede Erz-->
                            <dxi-item [dataField]="'erzberechtigte[0].anrede'" type="required" editorType="dxSelectBox" valueExpr="value" displayExpr="text" [editorOptions]="anredeErzSelectOptions">
                                <dxo-label text="{{'REGISTER.ANREDE' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZIEHUNGSBERECHTIGTER_ANREDE_AUSWAEHLEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Verheirateter Name -->
                            <!-- <dxi-item *ngIf="this.registrierungalsErz === 1" [dataField]="'erzberechtigte[0].verheirateterName'">
                                <dxo-label text="{{'REGISTER.VERHEIRATETER_NAME' | translate}}"></dxo-label>
                                <dxi-validation-rule type="pattern" [pattern]="namePattern" message="{{'REGISTER.ZAHLEN_VERHEIRATETEN_NAME' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.VERHEIRATETER_NAME_MAX_50' | translate}}"></dxi-validation-rule>
                            </dxi-item> -->
                            <!-- Nationalität Erz-->
                            <dxi-item [dataField]="'erzberechtigte[0].nationalitaet'" type="required" editorType="dxSelectBox" valueExpr="id" displayExpr="name" [editorOptions]="landErzSelectOptions">
                                <dxo-label text="{{'REGISTER.NATIONALITAET' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZIEHUNGSBERECHTIGTER_NATIONALITAET_AUSWAEHLEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Geburtsort Erz-->
                            <dxi-item *ngIf="daten.erzberechtigte[0].nationalitaet===gdService.it_id" [dataField]="'erzberechtigte[0].gebort'" type="required" editorType="dxSelectBox" valueExpr="name" displayExpr="name" [editorOptions]="geburtsortErzSelectOptions">
                                <dxo-label text="{{'REGISTER.GEBURTSORT_ITALIEN_NUR_STAAT' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZ_GEBURTSORT_EINGEBEN' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Geburtsort Erz Ausland-->
                            <dxi-item *ngIf="daten.erzberechtigte[0].nationalitaet!==gdService.it_id" [dataField]="'erzberechtigte[0].gebort'" type="required">
                                <dxo-label text="{{'REGISTER.GEBURTSORT' | translate}}"></dxo-label>
                                <dxi-validation-rule type="stringLength" [max]="50" message="{{'REGISTER.GEBURTSORT_MAX_50' | translate}}"></dxi-validation-rule>
                            </dxi-item>
                            <!-- Geburtsdatum Erz -->
                            <dxi-item [dataField]="'erzberechtigte[0].gebdatum'" editorType="dxDateBox" [editorOptions]="geburtsdatumOptionsErz" autocomplete="false">
                                <dxo-label text="{{'REGISTER.GEBURTSDATUM' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZ_GEBURTSDATUM_PFLICHT' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="custom" [validationCallback]="validateGebdatumErz" message="{{'REGISTER.ERZ_VOLLJAEHRIG' | translate}}"></dxi-validation-rule>
                                <!-- <dxi-validation-rule type="range" [max]="maxDate" message="Erziehungsberechtigte/r muss volljährig sein"></dxi-validation-rule> -->
                            </dxi-item>

                            <!-- Steuernummer Erz-->
                            <dxi-item [visible]="daten.erzberechtigte[0].nationalitaet===gdService.it_id" [dataField]="'erzberechtigte[0].steuernummer'" editorType="dxTextBox" [editorOptions]="steuernummerErzOptions">
                                <dxo-label text="{{'REGISTER.STEUERNUMMER' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.ERZ_STEUERNUMMER_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [min]="16" message="{{'REGISTER.ERZ_STEUERNUMMER_MIN_16' | translate}}"></dxi-validation-rule>
                                <!-- <dxi-validation-rule type="custom" [(validationCallback)]="validateSteuernummer2" [reevaluate]="true" [message]="daten.erzberechtigte[0].steuerfehlerTxt"></dxi-validation-rule> -->

                            </dxi-item>
                            <dxi-item [visible]="daten.erzberechtigte[0].nationalitaet===gdService.it_id" itemType="empty"></dxi-item>
                            <dxi-item [visible]="daten.erzberechtigte[0].nationalitaet===gdService.it_id" itemType="empty"></dxi-item>

                            <!-- <dxi-item itemType="group" caption="Besitzt der Erziehungsberechtigte eine Mitglieds- bzw. Kontaktkarte?" >
                            </dxi-item> -->
                            <dxi-item  [visible]="kontakte && mitgliedsnummer" itemType="group" cssClass="nummer" >
                                <!-- <dx-check-box id="hatNummer" [(value)]="hatNummer"  text="{{'PROFIL.JA' | translate}}" style="margin: 5px; margin-right: 15px;" (onValueChanged)="onValueChangedKontakt($event)">
                                    
                                </dx-check-box>
                                <dx-check-box id="hatkeineNummer" [(value)]="hatkeineNummer"  text="{{'PROFIL.NEIN' | translate}}"  style="margin: 5px; margin-left: 15px;" (onValueChanged)="onValueChangedKontakt($event)">
    
                                </dx-check-box> -->
                                <dxi-item itemType="simple" editorType="dxCheckBox" cssClass="nummer-ja" 
                                [editorOptions]="hatNummerOptionsErz">
                                </dxi-item>
                            </dxi-item>
                            <dxi-item  [visible]="kontakte && mitgliedsnummer" itemType="empty" >
                            </dxi-item>
                            <dxi-item  [visible]="kontakte && mitgliedsnummer" itemType="empty" >
                            </dxi-item>

                            <!-- Mitgliedsnummer Erz-->
                            <dxi-item [(visible)]="hatNummerErz" [colSpan]="3" [colCount]="3" itemType="group">
                                <dxi-item itemType="group" [caption]="bezeichnungErZNummer" [colSpan]="3"></dxi-item>
                                <dxi-item *ngIf="gdService.mmgdnummerEingabe(enuEingabeMitgliedsnummer.MitSektion) && !this.kontaktnummer" [dataField]="'erzberechtigte[0].mitgliedsnr'" type="required" editorType="dxTextBox" [editorOptions]="mitgliedsnummerErzOptions">
                                    <dxo-label text="{{'REGISTER.MITGLIEDSNUMMERFORMAT' | translate}}"></dxo-label>
                                    <dxi-validation-rule type="required" maxlength="5" message="{{'REGISTER.ERZMITGLIEDSNUMMEREINGEBEN' | translate}}"></dxi-validation-rule>
                                    <dxi-validation-rule type="pattern" [pattern]="mitgliedsnummerPattern" message="{{'REGISTER.ERZMITGLIEDSNUMMERMUSSFORMAT' | translate}}"></dxi-validation-rule>
                                </dxi-item>
                                <dxi-item *ngIf="gdService.mmgdnummerEingabe(enuEingabeMitgliedsnummer.OhneSektion) && !this.kontaktnummer" [dataField]="'erzberechtigte[0].mitgliedsnr'" type="required" editorType="dxTextBox" [editorOptions]="mitgliedsnummerErzOptions2">
                                    <dxo-label text="{{'REGISTER.MITGLIEDSNUMMERFORMAT2' | translate}}"></dxo-label>
                                    <dxi-validation-rule type="required" maxlength="5" message="{{'REGISTER.ERZMITGLIEDSNUMMEREINGEBEN' | translate}}"></dxi-validation-rule>
                                    <dxi-validation-rule type="stringLength" [max]="5" message="Die Mitgliedsnummer ist max. 5 Zahlen lang"></dxi-validation-rule>
                                </dxi-item>
                                <dxi-item *ngIf="this.kontaktnummer" [dataField]="'erzberechtigte[0].nummer'" type="required" editorType="dxTextBox" [editorOptions]="kartennummerErzOptions">
                                    <dxo-label *ngIf="gdService.mmgdnummerEingabe(enuEingabeMitgliedsnummer.MitSektion)" text="{{'REGISTER.KONTAKTNUMMERFORMAT' | translate}}"></dxo-label>
                                    <dxo-label *ngIf="gdService.mmgdnummerEingabe(enuEingabeMitgliedsnummer.OhneSektion)"  text="{{'REGISTER.KONTAKTNUMMERFORMAT2' | translate}}"></dxo-label>
                                </dxi-item>
                                <dxi-item itemType="group" horizontalAlignment="left"  [colSpan]="2">
                                    <dxi-item *ngIf="gdService.istKunde(enuKunde.AVS)" itemType="button" horizontalAlignment="left" [buttonOptions]="this.buttonInfoMitgliedsnummer" [colSpan]="2"></dxi-item>
                                    <dxi-item *ngIf="gdService.istKunde(enuKunde.AVS) && this.kontaktnummer" itemType="button" horizontalAlignment="left" [buttonOptions]="this.buttonInfoKontaktnummer" [colSpan]="2"></dxi-item>
                                    <dxi-item *ngIf="!gdService.istKunde(enuKunde.AVS)" itemType="empty" [colSpan]="2"></dxi-item>
                                </dxi-item>

                                <!-- prüfen Button Erz -->
                                <dxi-item itemType="simple" [colSpan]="3">
                                    <div *dxTemplate style="text-align: left;">
                                        <dx-button text="{{'REGISTER.PRUEFEN' | translate}}" (onClick)="onClickPruefen($event)" [template]="'buttonTemplate'" validationGroup="erzberechtKontaktValidator">
                                            <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                                <app-button [buttonData]="buttonData"></app-button>
                                            </div>
                                        </dx-button>
                                    </div>
                                 </dxi-item>
                                <dxi-item *ngIf="this.istGeprueftesMitgliedErz" itemType="simple" [colSpan]="2" editorType="dxCheckBox" [editorOptions]="{readOnly: true, text: this.mitgliedsPruefungTxtErz ,value: this.istGeprueftesMitgliedErz, isValid: this.istGeprueftesMitgliedErz}">
                                </dxi-item>
                            </dxi-item>
                            
                            <dxi-item *ngIf='this.hatNummerErz !== null' itemType="group" caption="{{'REGISTER.KONTAKTE_ERZIEHUNGSBERECHTIGTEN' | translate}}" [colSpan]="3"></dxi-item>

                            <!-- Email -->
                            <dxi-item  [dataField]="'erzberechtigte[0].email'" *ngIf='this.hatNummerErz && !istGeprueftesMitgliedErz' [disabled]='true' type="required">
                                <dxo-label text="{{'REGISTER.E-MAIL' | translate}}"></dxo-label>
                                <!-- <dxi-validation-rule type="async" message="E-Mmail Adresse ist bereits registriert" [validationCallback]="asyncEmailValidation"></dxi-validation-rule> -->
                            </dxi-item>
                            <dxi-item [dataField]="'erzberechtigte[0].email'" *ngIf='(!this.hatNummerErz && this.hatNummerErz !== null) || (this.hatNummerErz && istGeprueftesMitgliedErz)' type="required">
                                <dxo-label text="{{'REGISTER.E-MAIL' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.E-MAIL_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="email" message="{{'REGISTER.E-MAIL_UNGUELTIG' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="150" message="{{'REGISTER.E-MAIL_MAX_150' | translate}}"></dxi-validation-rule>
                                <!-- <dxi-validation-rule type="async" message="E-Mmail Adresse ist bereits registriert" [validationCallback]="asyncEmailValidation"></dxi-validation-rule> -->
                            </dxi-item>
                            <!-- Telefonnummer -->
                            <dxi-item  [dataField]="'erzberechtigte[0].telefon'" *ngIf='this.hatNummerErz && !istGeprueftesMitgliedErz' editorType="dxTextBox" [disabled]='true' type="required">
                                <dxo-label [text]="this.translate.instant('REGISTER.TELEFONNUMMER')"></dxo-label>
                            </dxi-item>
                            <dxi-item [dataField]="'erzberechtigte[0].telefon'" *ngIf='(!this.hatNummerErz && this.hatNummerErz !== null) || (this.hatNummerErz && istGeprueftesMitgliedErz)'  type="required" editorType="dxTextBox">
                                <dxo-label text="{{'REGISTER.TELEFONNUMMER' | translate}}"></dxo-label>
                                <dxi-validation-rule type="required" message="{{'REGISTER.TELEFONNUMMER_EINGEBEN' | translate}}"></dxi-validation-rule>
                                <dxi-validation-rule type="stringLength" [max]="20" message="{{'REGISTER.TELEFONNUMMER_MAX_20' | translate}}"></dxi-validation-rule>
                                <!-- <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Ungültige Nummer"> </dxi-validation-rule> -->
                            </dxi-item>
                        </dxi-item>
                    </dx-form>
                </form>
                <!-- Zugangsdaten -->
                <form *ngIf="!this.hatNummer || this.istGeprueftesMitglied" id="zugangsdaten" style="padding-top:0px;">
                    <dx-form style="margin-top:0px !important;" #formZugangsdaten [(formData)]="daten" [colCount]="3" [labelLocation]="'top'" validationGroup="zugangsdatenValidator" [showValidationSummary]="true">
                        <dxi-item itemType="group" caption="{{'REGISTER.DEINE_ZUGANGSDATEN' | translate}}" [colSpan]="3"></dxi-item>
                        <dxi-item [dataField]="'benutzername'" type="required" [editorOptions]="userfieldOptions">
                            <dxo-label text="{{'REGISTER.BENUTZERNAME' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.BENUTZERNAME_ADRESSE_EINGEBEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="custom"  message="{{'REGISTER.BENUTZERNAME_UNGUELTIGE_ZEICHEN' | translate}}" [validationCallback]="benutzernameKontrolle"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [max]="64" message="{{'REGISTER.MAXIMAL_64_ZEICHEN' | translate}}"></dxi-validation-rule>
                            <!-- <dxi-validation-rule type="async" message="{{'REGISTER.BENUTZERNAME_BEREITS_REGISTRIERT' | translate}}" [validationCallback]="asyncEmailValidation"></dxi-validation-rule> -->
                        </dxi-item>
                        <dxi-item [dataField]="'passwort'" type="required" [editorOptions]="passwordfieldOptions">
                            <dxo-label text="{{'REGISTER.PASSWORT' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.PASSWORT_EINGEBEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [min]="8" message="{{'REGISTER.PASSWORT_MIN_8_ZEICHEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="pattern" [pattern]="kennwortPattern" message="{{'REGISTER.PASSWORT_ANFORDERUNGEN' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <dxi-item [dataField]="'passwort2'" type="required" [editorOptions]="passwordfieldOptions2">
                            <dxo-label text="{{'REGISTER.PASSWORT_BESTAETIGEN' | translate}}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{'REGISTER.PASSWORT_BESTAETIGEN' | translate}}"></dxi-validation-rule>
                            <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison" message="{{'REGISTER.PASSWOERTER_NICHT_UEBEREIN' | translate}}"></dxi-validation-rule>
                        </dxi-item>
                        <!-- Newsletter -->
                        <dxi-item *ngIf="newsletter" itemType="group" caption="{{'REGISTER.NEWSLETTER' | translate}}" [colSpan]="3"></dxi-item>
                        <dxi-item *ngIf="newsletter" [dataField]="'newsletter'" itemType="simple" editorType="dxCheckBox" [editorOptions]="newsletterOptions" [colSpan]="3">
                            <dxo-label [visible]="false"></dxo-label>
                        </dxi-item>
                        <!-- Datenschuetz -->
                        <dxi-item *ngIf="this.dsgvoliste && this.dsgvoliste.length > 0" itemType="group" caption="{{'REGISTER.DATENSCHUTZ' | translate}}" [colSpan]="3"></dxi-item>
                        <dxi-item *ngFor="let dsgvo of this.dsgvoliste;let i = index" itemType="group" [colSpan]="3"
                            cssClass="dsgvoliste">
                
                            <dxi-item itemType="simple" caption="{{'REGISTER.DATENSCHUTZ' | translate}}" editorType="dxCheckBox"
                                [editorOptions]="{text: this.translate.instant('REGISTER.DATENSCHUTZBESTIMMUNGEN_AKZEPTIEREN') + ' \(' + dsgvo.name + '\)',value: false}"
                                [colSpan]="1">
                                <dxo-label [visible]="true">{{'REGISTER.DATENSCHUTZ' | translate}}</dxo-label>
                                <dxi-validation-rule type="compare" [comparisonTarget]="validateDatenschutz"
                                [message]="this.translate.instant('REGISTER.MUSST_DATENSCHUTZBESTIMMUNGEN_AKZEPTIEREN') + ' \(' +  dsgvo.name + '\)'">
                                </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item itemType="button" horizontalAlignment="left" [buttonOptions]="this.buttonInfoDatenschutz(dsgvo)"
                                [colSpan]="1"></dxi-item>
                            <dxi-item itemType="empty" [colSpan]="1"></dxi-item>
            
                        </dxi-item>

                        <!-- weiter Button -->
                        <dxi-item itemType="simple" [colSpan]="3">
                            <div *dxTemplate style="text-align: right;">
                                <dx-button text="{{'REGISTER.WEITER' | translate}}" (onClick)="onSubmit($event)" [disabled]='this.sendClicked' [template]="'buttonTemplate'" validationGroup="zugangsdatenValidator">
                                    <div *dxTemplate="let buttonData of 'buttonTemplate'" style="padding:0px;" >
                                        <app-button [buttonData]="buttonData"></app-button>
                                    </div>
                                </dx-button>
                            </div>
                         </dxi-item>
                    </dx-form>
                </form>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showRegisterMessage" class="inner-wrap formbackground" [style.margin]="getMarginOuter()">
    <div class="wpb_text_column wpb_content_element ">
        <div class="wpb_wrapper">
            <h3>{{'REGISTER.REGISTRIERUNG_BESTAETIGEN' | translate}}</h3>
            <div class="divider-wrap" data-alignment="default">
                <div style="margin-top: 25px; width: 20%px; height: 1px; margin-bottom: 25px;" data-width="20%" data-animate="" data-animation-delay="" data-color="default" class="divider-small-border"></div>
            </div>
            <p></p>
        </div>
    </div>
    <p>{{'REGISTER.E-MAIL_IN_KUERZE' | translate}} {{this.emailsendto}}.<br>{{'REGISTER.KLICK_E-MAIL_BESTAETIGEN' | translate}}</p>
</div>

<dx-popup [title]="this.translate.instant('REGISTER.KARTENINFO')" [width]="400" [height]="180" [showTitle]="true" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="karteninfoPopupVisible" contentTemplate="popupContent">
    <div *dxTemplate="let data of 'popupContent'">
        <p>{{'REGISTER.KARTENINFOTEXT' | translate}}</p>
    </div>
</dx-popup>

<dx-popup [title]="this.translate.instant('REGISTER.MITGLIEDSKARTE')" [width]="660" [height]="520" [showTitle]="true" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="mitgliedsnummerPopupVisible" contentTemplate="popupContent">
    <div *dxTemplate="let data of 'popupContent'">
        <p>{{'REGISTER.MITGLIEDSNUMMERFINDEN' | translate}}</p>
        <img width="100%" alt="AVS Mitgliedskarte" src="../assets/theme/AVSMitgliedskarte.jpg">
    </div>
</dx-popup>

<dx-popup [title]="this.translate.instant('REGISTER.KONTAKTKARTE')" [width]="660" [height]="520" [showTitle]="true" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="kontaktnummerPopupVisible" contentTemplate="popupContent">
    <div *dxTemplate="let data of 'popupContent'">
        <p>{{'REGISTER.KONTAKTNUMMERFINDEN' | translate}}</p>
        <img width="100%" alt="AVS Kontaktkarte" src="../assets/theme/AVSKontaktkarte.jpg">
    </div>
</dx-popup>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" [message]="this.translate.instant('DETAILSLADEN')">
</dx-load-panel>